
import {INVESTOR_LOG} from "../../mixins/call-log";
import CallLogTable from "./call-log-table.vue";

export default {
    name: "CallLogCard",
    components: {CallLogTable},
    props: {
        company: {
            type: Object,
            default: null,
        },
        contact: {
            type: Object,
            default: null,
        },
        initialActiveType: {
            type: Number,
            default: INVESTOR_LOG,
        }
    },
}
