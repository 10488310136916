
import TableFilters from "../table-filters.vue";
import TableActions from "../table-actions.vue";

export default {
    name: "CompaniesStaffedTable",
    components: {TableActions, TableFilters},
    data() {
        return {
            selectedRows: [],

            config: {
                columns: [
                    {
                        headline: "Companies Staffed",
                        sort: false,
                    },
                    {
                        headline: "Contacts Executive",
                        sort: false,
                    },
                    {
                        headline: "Contacts Finance",
                        sort: false,
                    },
                    {
                        headline: "Contacts Operations",
                        sort: false,
                    },
                    {
                        headline: "Contacts Total",
                        sort: false,
                    },
                ],
                ajaxUrl: `/api/companies/staffed-list`,
                search: true,
                pagination: 25,
                hideEmptyColumns: true,
                selectAll: true,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
            },
        }
    }
}
