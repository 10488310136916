import { render, staticRenderFns } from "./edit-call-log-modal.vue?vue&type=template&id=3188c608&scoped=true"
import script from "./edit-call-log-modal.vue?vue&type=script&lang=js"
export * from "./edit-call-log-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3188c608",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HintText: require('/codebuild/output/src214552897/src/web/components/hint-text.vue').default,SaveButton: require('/codebuild/output/src214552897/src/web/components/save-button.vue').default,ConfirmModal: require('/codebuild/output/src214552897/src/web/components/confirm-modal.vue').default})
