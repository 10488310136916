
import SaveButton from "../save-button.vue";
import {INVESTOR_LOG, MANAGEMENT_LOG, SOURCES, TYPES} from "../../mixins/call-log";
import HintText from "../hint-text.vue";
import iriPreparation from "../../mixins/iri-preparation";
import ConfirmModal from "../confirm-modal.vue";
import modals from "../../mixins/modals";

export default {
    name: "EditCallLogModalV2",
    components: {ConfirmModal, HintText, SaveButton},
    mixins: [iriPreparation, modals],
    props: {
        id: {
            type: String,
            default: "editCallLogModal"
        },
        callLogToEdit: {
            type: Object,
            default: null,
            required: true,
        },
        company: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: null,
            TYPES,
            SOURCES,
            INVESTOR_LOG,
            MANAGEMENT_LOG,
            callLogWorkingCopy: null,
            initialContactOptions: [],
        }
    },
    computed: {
        contactParams() {
            const ids = this.callLogWorkingCopy.investorCompanies.map(c => c.id);
            return {
                company: ids
            };
        },
        noteObjectTemplate() {
            return {
                id: null,
                needsNoCompanies: this.callLogWorkingCopy.callLogType === MANAGEMENT_LOG,
                note: "",
                companies: [],
            }
        }
    },
    watch: {
        'callLogWorkingCopy.investorContacts': {
            async handler(val, oldVal) {
                await this.$nextTick();

                const contactCompanies = val.map((investor) => investor.company).filter(company => company)
                const selectedInvestorCompanyIds = this.callLogWorkingCopy.investorCompanies.map((company) => company.id)
                for (let i = 0; i < contactCompanies.length; i++) {
                    if (!selectedInvestorCompanyIds.includes(contactCompanies[i].id)) {
                        selectedInvestorCompanyIds.push(contactCompanies[i].id)
                        this.callLogWorkingCopy.investorCompanies.push(contactCompanies[i]);
                    }
                }
            },
        },
        'callLogWorkingCopy.investorCompanies': {
            handler(val) {
                if (val) {
                    const companyIds = val.map((c) => c.id);
                    console.log("Loading contacts for companies", companyIds);
                    if (companyIds.length > 0) {
                        this.$axios.get(`/api/contacts/for_select?company=${companyIds.join(',')}`)
                            .then((response) => {
                                this.initialContactOptions = response.data['hydra:member'];
                            })
                            .catch(() => {
                                this.initialContactOptions = [];
                            })
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        'callLogWorkingCopy.callLogNotes': {
            handler(val) {
                const newVal = structuredClone(val);
                let performedChanges = false;
                for (let i = 0; i < newVal.length; i++) {
                    if (newVal[i].needsNoCompanies && newVal[i].companies.length > 0) {
                        newVal[i].companies = [];
                        performedChanges = true;
                    }
                }
                if (performedChanges) {
                    this.$set(this.callLogWorkingCopy, 'callLogNotes', newVal);
                }
            },
            deep: true,
        }
    },
    created() {
        const callLog = structuredClone(this.callLogToEdit);

        // Take only first part of a date time string
        if (callLog.date.includes("T"))
            callLog.date = callLog.date.split("T")[0];

        for (let i = 0; i < callLog.callLogNotes.length; i++) {
            callLog.callLogNotes[i].key = this.generateUUID();
        }
        this.callLogWorkingCopy = callLog;
    },
    methods: {
        submitForm() {
            this.loading = true;

            // Prepare data
            let finalCallLog = structuredClone(this.callLogWorkingCopy);

            if (this.callLogToEdit && this.callLogToEdit.id) {
                finalCallLog.id = this.callLogToEdit.id;
            } else {
                finalCallLog.id = null;
            }

            finalCallLog = this.prepareIri(finalCallLog);

            finalCallLog.source = finalCallLog.sourceOfContact;

            finalCallLog.investorCompanyIds = finalCallLog.investorCompanies.map(c => c.id);
            finalCallLog.investorContactIds = finalCallLog.investorContacts.map(c => c.id);
            finalCallLog.analystUserIds = finalCallLog.users.map(a => a.id);

            delete finalCallLog.investorCompanies;
            delete finalCallLog.investorContacts;
            delete finalCallLog.users;


            for (let i = 0; i < finalCallLog.callLogNotes.length; i++) {
                finalCallLog.callLogNotes[i].companyIds = finalCallLog.callLogNotes[i].companies.map(c => c.id);
                delete finalCallLog.callLogNotes[i].companies;
            }

            if (finalCallLog.id > 0) {
                this.$axios.patch(`/api/call_logs/${finalCallLog.id}/update`, finalCallLog, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }).finally(() => {
                    this.loading = false;
                    this.$emit('updated');
                });
            } else {
                this.$axios.post('/api/call_logs/add', finalCallLog).finally(() => {
                    this.loading = false;
                    this.$emit('updated');
                });
            }
        },
        cancel() {
            this.$emit('canceled');
        },
        addNotes() {
            const noteObject = structuredClone(this.noteObjectTemplate);
            noteObject.key = this.generateUUID();
            this.callLogWorkingCopy.callLogNotes.push(noteObject);
        },
        removeNoteBlock(note) {
            this.callLogWorkingCopy.callLogNotes = this.callLogWorkingCopy.callLogNotes.filter(function (obj) {
                return obj !== note;
            });
        },
        switchLog() {
            if (this.callLogWorkingCopy.callLogType === INVESTOR_LOG) {
                this.callLogWorkingCopy.callLogType = MANAGEMENT_LOG;
                this.callLogWorkingCopy.sourceOfContact = null;
                this.callLogWorkingCopy.callLogNotes.forEach((note) => {
                    note.companies = [];
                    note.needsNoCompanies = true;
                });
                if (this.company && !this.callLogWorkingCopy.investorCompanies.find((c) => c.id === this.company.id)) {
                    this.callLogWorkingCopy.investorCompanies.push(this.company);
                }
            } else {
                this.callLogWorkingCopy.callLogType = INVESTOR_LOG;
                this.callLogWorkingCopy.callLogNotes.forEach((note) => {
                    note.needsNoCompanies = false;
                });
            }
        },
        deleteCallLog() {
            this.modal = this.openModal(this.$refs.deleteCallLogModal);
        },
        doDeleteCallLog(id) {
            this.$emit("delete", id)
        },
    }
}
