
import SaveButton from "../save-button.vue";
import {LOG_TYPES, INVESTOR_LOG} from "../../mixins/call-log";

export default {
    name: "CallLogBulkOperationModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: 'callLogBulkOperationModal'
        },
        callLogNoteIds: {
            type: Array,
            default: () => [],
        },
        logType: {
            type: Number,
            required: true,
            validator: (value) => {
                return LOG_TYPES.includes(value);
            },
        },
    },
    data() {
        return {
            loading: false,
            operation: null,
            companies: [],
        }
    },
    computed: {
        bulkOptions() {
            const options = [
                {value: 'delete', label: 'Delete'},
            ];

            if(this.logType === INVESTOR_LOG) {
                options.push({value: 'add_companies', label: 'Add Companies'});
            }

            return options.sort((o1, o2) => o1.label.localeCompare(o2.label));
        }
    },
    watch: {},
    methods: {
        submitForm() {
            switch (this.operation) {
                case 'add_companies':
                    this.addCompanies();
                    break;
                case 'delete':
                    this.deleteCallLogNotes();
                    break;
                default:
                    alert("Unsupported Action");
            }
        },
        addCompanies() {
            this.$axios.post('/api/call_log_notes/bulk', {
                action: "ADD_COMPANIES",
                callLogNoteIds: this.callLogNoteIds,
                companyIds: this.companies.map(c => c.id)
            }).then(response => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: response.data.status ? "success" : "error",
                    title: response.data.status ? "Success" : "Error",
                    message: response.data.message,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }).finally(() => {
                this.$emit("call-log-notes-updated");
            });

        },
        deleteCallLogNotes() {
            if (!confirm(`You are about to delete ${this.pluralize(this.callLogNoteIds.length, 'call log')}. Do you want to continue?`)) {
                return;
            }

            this.$axios.post('/api/call_log_notes/bulk', {
                action: "DELETE",
                callLogNoteIds: this.callLogNoteIds,
            }).then(response => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: response.data.status ? "success" : "error",
                    title: response.data.status ? "Success" : "Error",
                    message: response.data.message,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }).finally(() => {
                this.$emit("call-log-notes-updated");
            });
        }
    }
}
